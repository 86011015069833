/**
 * Miscellaneous Interactions
 * 
 * @since 1.0.0
 * @package TEK Productions
 */

 (function($) {

 	$(document).ready(function() {
 		var usesTouch = ( $(window).width() || 'ontouchstart' in document.documentElement );

 		$(window).on('orientationchange', function() {
 			$('.active-bio').removeClass('active-bio');
 		})

 		// What We Do pages on mobile
        $('.subpages-menu-mobile-toggler').click(function() {
        	var h = $('.subpages-menu').css('height');

        	// If menu is closed, open it and change arrow direction
        	if ( h == '0px') {
        		$('.subpages-menu:first').css('height','auto');
        		$('.subpages-menu-mobile-toggler i.fa-angle-down').css('transform','rotate(-180deg)');
        	}
        	// Otherwise, close menu and change arrow direction
            else {
            	$('.subpages-menu:first').css('height','');
            	$('.subpages-menu-mobile-toggler i.fa-angle-down').css('transform','rotate(0deg)');
            }
        });

        // Who We Are page on mobile
        $('#team .thumbnail').click(function() {
        	// If uses touch or small screen
        	if ( usesTouch ) {
        		// If active, deactivate it
        		if ( $(this).hasClass('active-bio') ) {
        			$(this).removeClass('active-bio');
        		}
        		// Otherwise, deactivate all other instances and make active
        		else {
        			$('.active-bio').removeClass('active-bio');
        			$(this).addClass('active-bio');
        		}
        	}
        });

 	}); // document.ready
 })(jQuery);