/**
 * Initialize select2
 * 
 * @since 1.0.0
 * @package stlukes
 */
(function($) {
	$(document).ready(function() {

		if ($('select').length > 1){
            $('select').select2();
		}

	}); // document.ready
})(jQuery);