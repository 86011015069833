/**
 * Home CTA area, move cta-services behind gallery when user clicks on next and previous gallery buttons.
 * 
 * @since 1.0.0
 * @package tek
 */

(function($) {
	$(document).ready(function() {
		
		// Vars
		
		const portfolioSlider = document.querySelector('.portfolio-slider');

		
		// to see if the portfolio slider  is on the page or not.
		if (portfolioSlider !== null) {
			// if so create the local var 
			
			const sliderArrows = document.querySelector('.slider-arrows');
			const ctaServicesContainer = document.querySelector('.cta-services-container');


			sliderArrows.addEventListener('mouseenter', function(){

			ctaServicesContainer.classList.add('show');
		
	})
	// Mouseout when users exits the slider set z-index of cta-services back to normal.

	portfolioSlider.addEventListener('mouseleave', function(){
		ctaServicesContainer.classList.remove('show');
	})

		} else {
			
		};

		// CTA LightBox video play trigger.

		// Vars
		const videoContainer = document.querySelector('.cta-video');
		// to see if the lightbox video  is on the page or not.
		if (videoContainer !== null) {
			
			// if so create the local var 
			const videoTrigger = document.querySelector('.cta-vid-thumb-containter');
			

			// on click trigger video playback
			videoTrigger.addEventListener('click', function(){
				document.querySelector('.featherlight-inner > .vid').play();
			})

		} else {
			

		}

	}); // document.ready
})(jQuery);



		
