/**
 * Home page services interactions
 * 
 * @since 1.0.0
 * @package TEK Productions
 */
(function($) {

	$(document).ready(function() {

		$('.service-listings li:first-child').addClass('active'); 
		$('.service-images > div:first-child').addClass('active'); 

		$('.service-listings li').mouseover( function(){
			var currentService = $(this).attr('class').split(" ")[0];
			$('.service-listings li').removeClass('active'); 
			$('.service-images div').removeClass('active'); 

			$(this).addClass('active');
			$('.service-images').find('.' + currentService).addClass('active'); 
		});

	}); // document.ready
})(jQuery);