/**
 * Slick slider configs.
 * 
 * @since 1.0.0
 * @package stlukes
 */

(function($) {
	$(document).ready(function() {
        /** hijacking the slider script for rotating titles
		 *
         */
		var $titlerows = $('.secondary-title');
		var $total = $titlerows.length;
        var $i = 1;
        var $delay = 1000;
        var n = 1;
		function fadeTitles(n){
			var $currentItem = ".secondary-title-wrapper .secondary-title:nth-child("+n+")";

            $($currentItem).fadeIn(1000).css('display', 'inline-block').delay(3000).fadeOut(1000, function () {
                $i++;
                $delay = 1000 * $i * 5;
                if( $i < ($total+1) ) {
                    var intID = setInterval(fadeTitles($i), $delay);
                } else {
                	$i = 1;
                	$delay = 1000;
                    var intID = setInterval(fadeTitles($i), $delay);
				}
            });

        }
		fadeTitles($i);


		/**
		 * Check if slick.min.js is in scope/loaded!
		 */
		if ( ! ( 'slick' in $.fn ) ) {
			return;
		}


		// get slider and arrow svg
		var slider	= $( '.portfolio-slider' );
        var galleryslider	= $( '.services-slider' );
        var testimonialslider	= $( '.testimonial-slider' );
        var tips_slider = $( '.tips_slider');
		var arrow	= $( '.slider-arrow' );
		arrow		= arrow.html();


		/**
		 * Init sliders
		 */
		if (slider.length>0) {
            slider.slick({
                dots: false,
                arrows: true,
                appendArrows: '.slider-arrows',
                draggable: true,
                fade: true,
                speed: 600,
                responsive: [
                    {
                        breakpoint: 640,
                        settings: {
                            arrows: true,
                            appendArrows: '.slider-arrows'
                        }
                    }
                ]
            });
        };

        if (testimonialslider.length>0) {
            testimonialslider.slick({
                dots: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                appendArrows: '.testimonials-container .slider-arrows',
                draggable: true,
                fade: false,
                speed: 600,
                autoplaySpeed: 10000,
                responsive: [
                    {
                        breakpoint: 640,
                        settings: {
                            arrows: true,
                            slidesToShow: 1,
                            appendArrows: '.testimonials-container .slider-arrows',
                        }
                    }
                ]
            });
        };

        if (galleryslider.length>0) {
            galleryslider.slick({
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                draggable: true,
                arrows: true,
                focusOnSelect: false,
                //appendArrows: '.overview-gallery .slider-arrows',
                 prevArrow: '.slick-prev-P',
                 nextArrow: '.slick-next-N',
                responsive: [
                    {
                        breakpoint: 640,
                        settings: {
                            arrows: true,
                            slidesToShow: 1,
                            appendArrows: '.overview-gallery .slider-arrows'
                        }
                    }
                ]
            });
        }
        
        /* START Test of the SlickLightbox intergration */



        if (galleryslider.length>0) {
            galleryslider.slickLightbox({
          src: 'src',
          itemSelector: '.LB-Active img'
        });
        }
        

        /* END Test of the SlickLightbox intergration */

        if (tips_slider.length>0) {
            tips_slider.slick({
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                draggable: true,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 640,
                        settings: {
                            arrows: true,
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }



		/**
		 * Match height to get square slider
		 */
		function matchSliderHeight() {
			var slide = $( 'figure', slider );
			slide.height( slide.width() );
		}

		// on load
		matchSliderHeight();

		// on width change
		$( window ).resize( function() {
			setTimeout( matchSliderHeight, 500 );
		});



		/**
		 * Add "transitioning" class to slider on button click
		 */
		$( 'button', slider ).on( 'click', function() {
			
			slider.addClass( 'transitioning' );

			setTimeout( function() {
				slider.removeClass( 'transitioning' );
			}, 500 );
		});

	}); // document.ready
})



(jQuery);