/**
 * Shrink header on scroll
 * 
 * @since 1.0.0
 * @package stlukes
 */
(function($) {

	var header   = $( 'header[role=banner]' );

	$(document).on( 'scroll', function() {

		if ( $(document).scrollTop() > -100 ) {
			header.addClass( 'scrolled' );
		} else {
			header.removeClass( 'scrolled' );
		}

	}); // document.scroll

	$(document).ready(function() {
		if ( $(document).scrollTop() > -100 ) {
			header.addClass( 'scrolled' );
		}
	}); // document.ready

})(jQuery);