/**
 * Reveal Button
 * 
 * @since 1.0.0
 * @package TEK Productions
 */

(function($) {

    $(document).ready(function() {
  if(window.location.href.indexOf("#Get-Started") > -1) {
       $('.virtual-form.form-wrapper.panel').css('display','block');
    }

		$(".flip").click(function() {
			$(".panel").slideToggle("slow");
			console.log('Reveal');

			$('html,body').animate({
        scrollTop: $(".virtual-form-btn.flip").offset().top},
        'slow');
			console.log('Scroll');
		});
	});
})(jQuery);

