/**
 * Shrink header on scroll
 * 
 * @since 1.0.0
 * @package stlukes
 */
(function($) {
	$(document).ready(function() {

		// $('.tooltip-circle').on('hover', function(e) {
		// 	$(this).parent().toggleClass("open"); //you can list several class names 
		// 	e.preventDefault();
		// });

		$('.tooltip-circle').hover(
			function(){ $(this).parent().removeClass('closed') },
			function(){ /* $(this).parent().addClass('closed') */ }
		);

		$('.tooltip-circle').hover(
			function(){ $(this).parent().addClass('open') },
			function(){ /* $(this).parent().removeClass('open') */ }
		);

		$('.tooltip-trigger').hover(
			function(){ /* Do nothing on hover */ },
			function(){
				/* If not hovering and not.close, then .close  */
				if ( $(this).hasClass('open') ) {
					$(this).removeClass('open')
					$(this).addClass('closed')
				}
			}
		);

	}); // document.ready
})(jQuery);