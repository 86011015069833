/**
 * Skrollr
 *
 * @since   1.0.0
 * @package tek
 */ 

(function($) {

	// initialize skrollr if the window width is large enough
	if ($(window).width() > 1184) {
		var s = skrollr.init({forceHeight: false});
	}

}(jQuery));