/**
 * Hero video player mute button
 * 
 * @since 1.0.0
 * @package tek
 */

(function($) {
	$(document).ready(function() {
		
		// Vars
		const videoPlayer = document.querySelector('.hero-bg-video')
		
		// to see if the videoPlayer is on the page or not.
		if (videoPlayer !== null) {
			// if so load the mute button.
			// mute button
			const video = videoPlayer.querySelector('.bgvid')
			const mute = videoPlayer.querySelector('.mute')

	mute.addEventListener('click', function(){
		video.muted = !video.muted
		mute.classList.toggle('unmuted')
	})
		} else {
			
		}


	



	}); // document.ready
})(jQuery);